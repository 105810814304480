const fonts: { [k: string]: string } = {
  sans: 'open-sans, "Segoe UI", sans-serif',
  monospace:
    'Menlo, "Cascadia Code", "Operator Mono", "Fira Code", "Dank Mono", Consolas, monospace',
}

fonts.body = 'open-sans, sans-serif'
fonts.thin = 'open-sans, sans-serif'
fonts.heading = 'open-sans, sans-serif'

const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96].map(
  (px) => `${px / 16}rem`
)
const fontWeights = {
  book: 400,
  body: 400,
  heading: 400,
  bold: 700,
  xBold: 800,
}
const lineHeights = {
  body: 1.25,
  heading: 1.25,
}
const letterSpacings = {
  body: 'normal',
  caps: '0.2em',
}

export { fonts, fontSizes, fontWeights, lineHeights, letterSpacings }
