const linkBase = {
  textDecoration: 'none',
  '&:hover': {
    color: 'brand.coral',
  },
  '&:focus': {
    boxShadow: 'outline',
  },
  transitionProperty: 'opacity, box-shadow, transform, background-color, color',
  transitionDuration: '150ms',
  transitionTimingFunction: 'ease-out',
}

export const text = {
  thin: {
    fontFamily: 'thin',
    fontWeight: 'book',
  },
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
  },
  body: {
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
  },
  disclaimer: {
    variant: 'text.thin',
    color: '#B2B2C2',
    fontSize: 0,
  },
  link: {
    ...linkBase,
    textDecoration: 'underline',
  },
  footerLink: {
    ...linkBase,
    color: '#B2B2C2',
    py: '7px',
    fontSize: '14px',
    fontFamily: 'haboro-soft, sans-serif',
    fontWeight: '500',
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
      textDecoration: 'underline',
    },
  },
}
