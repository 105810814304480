/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// You can delete this file if you're not using it
import './src/assets/css/foundation.css'
import './src/assets/css/custom.css'
import { ThemeProvider } from 'theme-ui'
import React from 'react'
import { defaultTheme } from './src/theme'

//import NotSupported from './src/pages/not-supported'

const Providers = ({ children }) => {
  const detectIE = () => {
    const ua = window.navigator.userAgent

    const msie = ua.indexOf('MSIE ')
    if (msie > 0) {
      // IE 10 or older => return version number
      //return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      return true
    }

    const trident = ua.indexOf('Trident/')
    if (trident > 0) {
      // IE 11 => return version number
      //const rv = ua.indexOf('rv:')
      //return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      return true
    }

    // other browser
    return false
  }

  // if (detectIE()) {
  //   return <NotSupported></NotSupported>
  // }

  return (
    <ThemeProvider theme={{ ...defaultTheme, useCustomProperties: false }}>
      {children}
    </ThemeProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}

// export const onRouteUpdate = () => {
//   window.ScrollOut({
//     cssProps: { viewportY: true },
//     threshold: 0.4,
//     targets: '[data-scroll]',
//     /*onShown: function( element ) {
// 			if ( element.classList.contains( 'section-content' ) ) {
// 				var innerElements = element.querySelectorAll( 'h2,h5' );
// 				innerElements.forEach( heading => {
// 					heading.classList.add( 'visible' );
// 				} );
// 			}
// 		},
// 		onHidden: function( element ) {
// 			if ( element.classList.contains( 'section-content' ) ) {
// 				var innerElements = element.querySelectorAll( 'h2,h5' );
// 				innerElements.forEach( heading => {
// 					heading.classList.remove( 'visible' );
// 				} );
// 			}
// 		},*/
//   })
// }
