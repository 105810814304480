const base = {
  appearance: 'none',
  outline: 'none',
  display: 'inline-block',
  textAlign: 'center',
  lineHeight: 'inherit',
  textDecoration: 'none',
  fontSize: 'inherit',
  fontFamily: 'body',
  letterSpacing: 'button',
  px: 3,
  py: 2,
  border: 0,
  transitionProperty: 'opacity, box-shadow, transform, background-color',
  transitionDuration: '150ms',
  transitionTimingFunction: 'ease-out',
  '&[disabled]': {
    opacity: 0.5,
    boxShadow: 'none',
    pointerEvents: 'none',
  },
  '&:focus': {
    boxShadow: 'outline',
  },
}
const primary = {
  ...base,
  display: 'block',
  bg: 'brand.tealDark',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'brand.tealDark',
  color: 'white',
  py: '10px',
  textAlign: 'center',
  fontWeight: 'normal',
  fontSize: '19px',
  textDecoration: 'none',
  borderRadius: '5px',
  maxWidth: '300px',
  '&:hover': {
    bg: 'white',
    color: 'brand.tealDark',
  },
}

export const buttons = {
  base,
  nav: {
    ...base,
  },
  primary,
  yellow: {
    ...primary,
    bg: 'brand.yellow',
    color: 'brand.navy',
    textShadow: '1px 1px 0px #34A29C',
    borderWidth: '0',
    '&:hover': {
      color: 'brand.yellow',
      bg: 'brand.coral',
    },
  },
  teal: {
    ...primary,
    bg: 'brand.tealDark',
    color: 'white',
    textShadow: '1px 1px 0px #34A29C',
    borderWidth: '0',
    '&:hover': {
      bg: 'brand.coral',
    },
  },
}
